ul.Acoes {
    position: absolute;
    bottom: 10px;
}
 
.actionBackground {
  background-color:#0B0B0B;
}

.actionBackground aside {
  background-color: #0B0B0B !important;
}

.actionBackground aside .treeview > a {
  color: #F5F1E1;
}

.actionBackground header {
  background-color: #ff820e;
}

.actionBackground header > a {
  background-color: transparent !important;
}

.actionBackground header > .navbar {
  background-color: #ff820e !important;
}

.actionBackground header > .navbar > a {
  color: #0B0B0B !important;
}

.actionBackground header > .navbar > a:hover {
  background: #ff820e !important;
}

.actionBackground header > .navbar .navbar-custom-menu > ul li a {
  color: #0B0B0B !important;
}

.actionBackground header > .navbar .navbar-custom-menu ul.dropdown-menu li.user-header {
  background: #ff820e !important;
}

.actionBackground header > .navbar .navbar-custom-menu ul.dropdown-menu li.user-header p {
  color: #0B0B0B !important
}

.actionBackground aside .treeview-menu {
  background: #F0ECDD !important;
  margin: 0;
  padding: 0;
  border-left: 5px solid #ff820e;
}

.actionBackground aside .treeview-menu li {
  border-bottom: 1px solid #ff820e;
}

.actionBackground aside .treeview-menu li:last-child {
  border-bottom: none;
}

.actionBackground aside .treeview-menu li:hover {
  background: rgba(11, 11, 11, .5) !important;
}

.actionBackground .treeview.menu-open:hover {
  background: transparent !important
}

.actionBackground aside .treeview-menu a {
  color: #0B0B0B !important;
}

.actionBackground aside .treeview-menu a:hover {
  color: #F0ECDD !important;
}

.actionBackground .treeview.menu-open > a:hover {
  color: #0B0B0B !important;
}



.actionBackground aside > section > ul > li.treeview.menu-open > a:hover {
  color: #F0ECDD !important
}

.elementoGrid {
    border: 1px solid black;
}

.tabelawidgets {
  width: 90%;
  height: 400px;
  overflow-y: auto;
}

.scroll {
  overflow: hidden;
  overflow: auto;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.botaowidget {
  z-index: 99;
}

.cardFooter {
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.card {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  word-wrap: break-word;
  background-color: #fff; 
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: 3rem;
  margin-top: 20px;
  padding: 10px;
  height: 300px;
}

.buttonHover {
  background-color: transparent
}

.buttonHover:hover {
  background-color: rgb(255, 255, 255);
}