@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
 * {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}

html, body, #root {
  min-height: 100%;
}

body {
  /* background: #1360AE; */
  /* background-image: linear-gradient(to right, rgba(255,0,0,0), #1360AE); */
  background: #F0ECDD;
  background-size: cover;
  -webkit-font-smoothing: antialiased !important;  
}

body, input, button {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.container {
  position: absolute;
    left: 10px;
    bottom: 10px;
  /* margin: 80px auto 0; */
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.linha-vertical {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 2px;
  height: 450px;/*Altura da linha*/
  margin-top: -225px;
  border-left: 2px solid;/* Adiciona borda esquerda na div como ser fosse uma linha.*/
  border-color: #ff820e;
  box-sizing: border-box;
}

.logoJCA {
  position: absolute;
  left: 25%;
  top: 50%;
  width: 300px;
  border-radius: 4px;
  padding: 30px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* background-color: #ebecec; */
}

.simbolo {
  position: fixed;
  left: 150px;
  top: -50px;
  width: 100px;
  background-color: rgb(230, 230, 230);
  border-radius: 50%;
}

.contentLogin {
  position: absolute;
  left: 75%;
  top: 50%;
  border: 1px solid #999;
  width: 400px;
  background: #0B0B0B;
  border-radius: 8px;
  padding: 30px;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.contentLogin > p {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 30px;
}

.contentLogin form {
  display: flex;
  flex-direction: column;
}

.contentLogin form label {
  font-size: 14px;
  color: #F5F1E1;
  font-weight: bold;
  margin-bottom: 8px;
}

.contentLogin form label span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}

.contentLogin form input {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 2px;
  height: 46px;
  padding: 0 15px;
  font-size: 16px;
}

.contentLogin button.btn {
  border: 0;
  border-radius: 2px;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  background: #ff820e;
  color: #222222;
  cursor: pointer;
  border-radius: 5px;
}

.contentLogin button.btn:hover {
  background: #ff820e;
}

.contentLogin > h2 {
  color: #ff820e;
  margin: 20px 0;
}

.override {
  display: block;
  margin: 0 auto;
  border-color: red;
}
.containerCheckBoxLogin{
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
}
.containerCheckBoxLogin input{
  accent-color: #19B3A8;
  margin-right: 10px;
  width: 1.15em;
  height: 1.15em;
}


.centerContainerLogin {
  background-color: #DEDEDE; 
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.passwordStyle {
  display: flex;
}
.passwordStyleInput {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 20px;
  height: 46px;
  padding: 0 15px;
  font-size: 16px;
  width: 290px;
  border-radius: 20px 0px 0px 20px !important;
}

.passwordStyleView {
  border: 0;
  border-radius: 0px 20px 20px 0px;
  height: 46px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  background: #ff820e;
  color: #222222;
  cursor: pointer;
}

.passwordStyleView:hover {
  background: #ff820e;
}

.h1TextAlig{
  text-align: center;
  margin-bottom: 40px;
  color: #ff820e;
  font-size: 30px;
}
.styleLogin {
  width: 400px;
  background: #0B0B0B;
  border-radius: 20px;
  padding: 30px;
}

.styleLogin > p {
  font-size: 22px;
  line-height: 30px;
  margin-bottom: 30px;
}

.styleLogin form {
  display: flex;
  flex-direction: column;
}

.styleLogin form label {
  font-size: 16px;
  color: #F5F1E1;
  font-weight: bold;
  margin-bottom: 10px;
}

.styleLogin form label span {
  font-weight: normal;
  color: #999;
  font-size: 12px;
}

.styleLoginformInput {
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 20px;
  height: 46px;
  padding: 0 15px;
  font-size: 16px;
}

.styleLogin button.btn {
  border: 0;
  border-radius: 20px;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  font-size: 16px;
  font-weight: bold;
  background: #ff820e;
  color: #222222;
  cursor: pointer;
}

.styleLogin button.btn:hover {
  background: #ff820e;
}

.dashboardMapFirst {

    height: calc(100vh - 82px);
}

.loader-container-map {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.834);
    z-index: 1;
}

.spinner-map {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: #3C8DBC transparent #3C8DBC transparent;
    border-radius: 50%;
    -webkit-animation: spin-anim 1.2s linear infinite;
            animation: spin-anim 1.2s linear infinite;
}

@-webkit-keyframes spin-anim {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes spin-anim {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
._loading_overlay_content {
    top: 45%;
    left: 45%;
    position: fixed;
  }
  
#entrada-table-view thead th.whiteSpace {
    white-space: nowrap;
}

#entrada-table-view thead th.defaultWidth {
    width: 1px;
}

#entrada-table-view thead th.textAlignCenter {
    text-align: center;
}

#entrada-table-view thead th.textAlignRight {
    text-align: right;
}
.ladoALado {
  display: inline-block;
}


/* MODAL */

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
    .modal-main {
      position:fixed;
      background: white;
      width: 80%;
      height: auto;
      top:50%;
      left:50%;
      -webkit-transform: translate(-50%,-50%);
              transform: translate(-50%,-50%);
    }
.telegestaoMap{
  height: calc(100vh - 82px);
  position: relative;
}

.relatorio-alerta {
  width: 150px;
  background-color: white;
  position: absolute;
  z-index: 1500;
  right: 10px;
  top: 10px;
  border-radius: 5px;
  padding: 10px;
}


.relatorio-alerta .alert-row {
  cursor: pointer;
  padding: 5px;
}

.relatorio-alerta .alert-row img {
  height: auto; 
  width: auto; 
  max-width: 30px; 
  max-height: 30px;
}

.relatorio-alerta .alert-row:hover {
  background-color: rgba(0,0,0,.2);
}

.relatorio-alerta .alert-row .info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lum-0 {
  -webkit-filter: brightness(5%);
          filter: brightness(5%);
}

.lum-1 {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
}

.leaflet-container {
  height: 100vh;
}

#dashboard-modal .box-footer {
  display: flex;
  justify-content: end;
}

#dashboard-modal .contents > div {
  display: none;
}
#dashboard-modal .contents > div.active {
  display: flex;
}
#dashboard-modal .nav.nav-tabs li, 
#dashboard-modal .nav.nav-tabs li a {
  cursor: pointer;
}

#dashboard-modal > div {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

#dashboard-modal .dashboard-content .columns {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

#dashboard-modal .dashboard-content .equipament {
  width: 260px;
  height: 430px;
  justify-content: space-evenly;
  align-content: space-evenly;
}

#dashboard-modal .dashboard-content .metrics {
  width: 620px;
  height: 230px;
  justify-content: space-evenly;
  align-content: space-evenly;
}

#dashboard-modal .dashboard-content .programation {
  width: 620px;
  height: 200px;
  justify-content: space-evenly;
  align-content: space-evenly;
}

#dashboard-modal .dashboard-content .equipament, 
#dashboard-modal .dashboard-content .metrics, 
  #dashboard-modal .dashboard-content .programation {
  background-color: rgba(0,0,0,0.2);
}

.d-flex {
  display: flex;
}

.f-wrap {
  flex-wrap: wrap;
}

.f-dir-row {
  flex-direction: row;
}

.f-dir-col {
  flex-direction: column;
}

.jc-sa,
.f-jc-sa {
  justify-content: space-around;
}

.f-jc-c,
.f-jc-center {
  justify-content: center;
}

.w-fit-content {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.f-1 {
  flex: 1 1;
}

.f-2 {
  flex: 2 1;
}

.f-3 {
  flex: 3 1;
}

.simple-data-container {
  background-color: white;
  border-radius: 5px;
  padding: 15px;
  width: -webkit-max-content;
  width: max-content;
  height: 70px;
  align-content: flex-start;
}

.simple-data-container.image {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.simple-data-container .info {
  font-weight: bold;
}

.simple-data-container .icon {
  margin-right: 5px;
}

.simple-data-container.image img {
  width: 100%;
}


/* body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
} */

.alerts-list-container {
  max-height: inherit;
  overflow-y: auto;
  height: inherit;
  width: inherit;
  max-width: inherit;
  text-align: center;
}

.alerts-list {
  list-style: none;
  overflow: auto; /* Adiciona uma barra de rolagem se necessário */
}

.list {
  text-align: center;
  position: absolute;
}

.timeline-container {
  width: 80%;
  max-width: 800px;
  position: relative;
  margin-bottom: 20px;
}

.timeline-event {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #3498db;
  border-radius: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}

.timeline-event:hover {
  background-color: #2980b9;
}

.timeline-event::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #3498db;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.event-info {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: inline-block;
}

ul.Acoes {
    position: absolute;
    bottom: 10px;
}
 
.actionBackground {
  background-color:#0B0B0B;
}

.actionBackground aside {
  background-color: #0B0B0B !important;
}

.actionBackground aside .treeview > a {
  color: #F5F1E1;
}

.actionBackground header {
  background-color: #ff820e;
}

.actionBackground header > a {
  background-color: transparent !important;
}

.actionBackground header > .navbar {
  background-color: #ff820e !important;
}

.actionBackground header > .navbar > a {
  color: #0B0B0B !important;
}

.actionBackground header > .navbar > a:hover {
  background: #ff820e !important;
}

.actionBackground header > .navbar .navbar-custom-menu > ul li a {
  color: #0B0B0B !important;
}

.actionBackground header > .navbar .navbar-custom-menu ul.dropdown-menu li.user-header {
  background: #ff820e !important;
}

.actionBackground header > .navbar .navbar-custom-menu ul.dropdown-menu li.user-header p {
  color: #0B0B0B !important
}

.actionBackground aside .treeview-menu {
  background: #F0ECDD !important;
  margin: 0;
  padding: 0;
  border-left: 5px solid #ff820e;
}

.actionBackground aside .treeview-menu li {
  border-bottom: 1px solid #ff820e;
}

.actionBackground aside .treeview-menu li:last-child {
  border-bottom: none;
}

.actionBackground aside .treeview-menu li:hover {
  background: rgba(11, 11, 11, .5) !important;
}

.actionBackground .treeview.menu-open:hover {
  background: transparent !important
}

.actionBackground aside .treeview-menu a {
  color: #0B0B0B !important;
}

.actionBackground aside .treeview-menu a:hover {
  color: #F0ECDD !important;
}

.actionBackground .treeview.menu-open > a:hover {
  color: #0B0B0B !important;
}



.actionBackground aside > section > ul > li.treeview.menu-open > a:hover {
  color: #F0ECDD !important
}

.elementoGrid {
    border: 1px solid black;
}

.tabelawidgets {
  width: 90%;
  height: 400px;
  overflow-y: auto;
}

.scroll {
  overflow: hidden;
  overflow: auto;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.botaowidget {
  z-index: 99;
}

.cardFooter {
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

.card {
  position: relative;
  display: flex;
  flex-direction: row;
  word-wrap: break-word;
  background-color: #fff; 
  background-clip: border-box;
  border: 2px solid rgba(0, 0, 0, 0.125);
  border-radius: 3rem;
  margin-top: 20px;
  padding: 10px;
  height: 300px;
}

.buttonHover {
  background-color: transparent
}

.buttonHover:hover {
  background-color: rgb(255, 255, 255);
}
