/* body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
} */

.alerts-list-container {
  max-height: inherit;
  overflow-y: auto;
  height: inherit;
  width: inherit;
  max-width: inherit;
  text-align: center;
}

.alerts-list {
  list-style: none;
  overflow: auto; /* Adiciona uma barra de rolagem se necessário */
}

.list {
  text-align: center;
  position: absolute;
}

.timeline-container {
  width: 80%;
  max-width: 800px;
  position: relative;
  margin-bottom: 20px;
}

.timeline-event {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #3498db;
  border-radius: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.timeline-event:hover {
  background-color: #2980b9;
}

.timeline-event::before {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #3498db;
  left: 50%;
  transform: translateX(-50%);
}

.event-info {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: inline-block;
}
